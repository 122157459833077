import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureRequestComponent } from './feature-request.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {DxButtonModule} from 'devextreme-angular';



@NgModule({
  declarations: [
    FeatureRequestComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, TranslateModule, MatDialogModule, MatButtonModule, MatIconModule, DxButtonModule]
})
export class FeatureRequestModule { }
