import {Component, EventEmitter, Input, OnInit, Output, ɵmarkDirty as markDirty} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {
  fadeInLeftOnEnterAnimation,
} from "angular-animations";
import {AppState} from "../../store/app.reducer";
import {Store} from "@ngrx/store";
import {getPlans} from "../../store/user/user.selector";
import {map} from "rxjs/operators";
import {FeatureRequestComponent} from '../feature-request/feature-request.component';
import {MatDialog} from '@angular/material/dialog';

interface ProductsMenuItem {
  id: number;
  label: string;
  pool_type: string;
}

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [
    fadeInLeftOnEnterAnimation({anchor: 'enter', duration: 500})
  ]
})
export class LeftMenuComponent implements OnInit {
  
  productsMenuItems: ProductsMenuItem[] = [];
  
  /**
   * Check if left menu is shown for user
   * @type {boolean}
   */
  @Input() isShown!: boolean;
  
  /**
   * Emmit event to toggle left menu
   * @type {EventEmitter<void>}
   */
  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();

  env = environment;
  constructor(public router: Router, private store: Store<AppState>, private dialog: MatDialog) {
    this.router.events
      .subscribe(val => {
        if (val instanceof NavigationEnd)
          markDirty(this);
    })
    this.store.select(getPlans).pipe(map(plans => {
      return plans.map(plan => {
        return {
          label: plan.label,
          id: plan.plan_id,
          pool_type: plan.pool_type
        }
      })
    })).subscribe(data =>
      this.productsMenuItems = data.sort((a, b) => b.id -  a.id)
    )
  }

  ngOnInit(): void {
  }
  
  /**
   * Emmit event to toggle left menu on module link click
   * @return {void}
   */
  menuItemClickHandler(): void {
    if (window.innerWidth < 1200)
      this.menuToggle.emit();
  }
  
  showForm(e: any) {
    e.preventDefault();
    const dialogRef = this.dialog.open(FeatureRequestComponent, {
      width: '550px',
      minHeight: '250px',
      disableClose: true,
      data: {}
    })
  }
}
