<h2 mat-dialog-title class="font-f">{{'Choose payment method' | translate}}</h2>
<mat-dialog-content class="mat-typography">

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12 p-0">
					<div class="radio form-group" *ngIf="data.payment_systems.strp">
						<input id="p6" class="form-check-input" type="radio" name="availPayment" [(ngModel)]="selectedPs" value="strp-checkout">
						
						<label for="p6" class="font-f">{{'Card Payment' | translate}}<p class="fs-10">{{'Powered by' | translate}} Stripe</p></label>
						<img src="./assets/images/Master-Card-icon.png" alt="">
						<img src="./assets/images/Visa-icon.png" alt="" class="px-1">
					</div>

					<div class="radio form-group" *ngIf="data.payment_systems.fs">
						<input id="p3" class="form-check-input" type="radio" name="availPayment" [(ngModel)]="selectedPs" value="fs-pp">

						<label for="p3" class="font-f">{{'PayPal' | translate}}<p class="fs-10">{{'Powered by' | translate}} FastSpring</p></label>
						<img src="./assets/images/Paypal-icon.png" alt="">
					</div>
					<div class="radio-notify" *ngIf="data.payment_systems.fs && data.is_subscription && selectedPs === 'fs-pp'">
							<span class="red mr-1">*</span> {{'Please note that with FastSpring, the full subscription amount will be charged immediately upon subscribing, unlike Stripe, which charges once you reach your selected data limit.' | translate}}
					</div>
					
					<div class="radio form-group" *ngIf="data.payment_systems.pssn && !data.is_subscription">
						<input id="p7" class="form-check-input" type="radio" name="availPayment" [(ngModel)]="selectedPs" value="pssn">
						
						<label for="p7" class="font-f">{{'AliPay' | translate}}<p class="fs-10">{{'Powered by' | translate}} Payssion</p></label>
						<img class="mr-1" src="./assets/images/alipay_icon.png" alt="">
					</div>

					<div class="radio form-group" *ngIf="data.payment_systems.paddle && !data.is_subscription">
						<input id="p8" class="form-check-input" type="radio" name="availPayment" [(ngModel)]="selectedPs" value="paddle">
						
						<label for="p8" class="font-f">{{'AliPay' | translate}}<p class="fs-10">{{'Powered by'}} Paddle</p></label>
						<img class="mr-1" src="./assets/images/alipay_icon.png" alt="">
					</div>
					
					<div class="radio form-group" *ngIf="data.payment_systems.cm && !data.is_subscription">
						<input id="p5" class="form-check-input" type="radio" name="availPayment" [(ngModel)]="selectedPs" value="cm">
						
						<label for="p5" class="font-f">{{'Cryptocurrency' | translate}}<p class="fs-10">{{'Powered by' | translate}} Cryptomus</p></label>
						<img class="mr-1" src="./assets/images/tether-usdt-trc20.png" alt="">
						<img class="ml-1" src="./assets/images/bitcoin.png" alt="">
						<img class="ml-1" src="./assets/images/ethereum.png" alt="">
						<img src="./assets/images/litecoin.png" alt="">
					</div>
					
					<div class="radio form-group" *ngIf="data.payment_systems.np && !data.is_subscription">
						<input id="p4" class="form-check-input" type="radio" name="availPayment" [(ngModel)]="selectedPs" value="np">
						
						<label for="p4" class="font-f">{{'Cryptocurrency' | translate}}<p class="fs-10">{{'Powered by' | translate}} NowPayments</p></label>
						<img class="mr-1" src="./assets/images/tether-usdt-trc20.png" alt="">
						<img class="ml-1" src="./assets/images/bitcoin.png" alt="">
						<img class="ml-1" src="./assets/images/ethereum.png" alt="">
						<img src="./assets/images/litecoin.png" alt="">
					</div>
					
					<div class="radio-notify" *ngIf="(data.payment_systems.cm && selectedPs === 'cm') || (data.payment_systems.np && selectedPs === 'np')">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" [(ngModel)]="cryptoNonRefundAccepted" id="cryptoNonRefundCheck">
							<label class="form-check-label" for="cryptoNonRefundCheck">
								{{'I understand and agree that cryptocurrency payments are final and non-refundable.' | translate}}<mat-icon [matTooltip]="('By choosing to pay with cryptocurrency, you agree that crypto payments cannot be refunded. This rule applies no matter what is mentioned by the payment provider on their site. Any statements that say otherwise won’t be valid.' | translate)" inline="true">help</mat-icon>
							</label>
						</div>
					</div>
					
					<div class="radio-notify mb-1" *ngIf="(data.payment_systems.np || data.payment_systems.cm) && !data.is_subscription"><a href="https://dataimpulse.com/crypto-payment-guide/" target="_blank">{{'How to pay in crypto?' | translate}}</a></div>
				</div>
			</div>
		</div>

</mat-dialog-content>
<mat-dialog-actions align="end">
	<button type="button" class="btn btn-light btn-sm" mat-button [mat-dialog-close]="false">
		{{'Cancel' | translate}}
	</button>
	<button type="button" class="btn btn-default-small dx-button-text" mat-button [mat-dialog-close]="selectedPs" cdkFocusInitial (click)="initiateCheckout()" [disabled]="(selectedPs === 'np' || selectedPs === 'cm') && !cryptoNonRefundAccepted">
		{{'Continue' | translate}}
	</button>
</mat-dialog-actions>
