import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftMenuComponent } from './left-menu.component';
import {RouterModule} from "@angular/router";
import {CoreModule} from "../../core.module";
import {FeatureRequestModule} from '../feature-request/feature-request.module';



@NgModule({
  declarations: [
    LeftMenuComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    FeatureRequestModule
  ],
  exports: [LeftMenuComponent]
})
export class LeftMenuModule { }
