<div mat-dialog-title class="dialog-title font-f">
	<div>{{'We’re Open to Your Ideas!' | translate}}</div>
	<button mat-icon-button aria-label="close dialog" [mat-dialog-close]="true">
		<mat-icon>close</mat-icon>
	</button>
</div >
<div mat-dialog-content>
	<ng-container *ngIf="!showSuccessMessage; else successTemplate">
		<form [formGroup]="frForm" (ngSubmit)="sendFeatureRequest()">
			<mat-form-field appearance="outline" cdkFocusInitial>
				<textarea matInput placeholder="{{'Want a new feature? Let us know!' | translate}}" rows="5" formControlName="message"></textarea>
			</mat-form-field>
			<mat-error *ngIf="frForm.get('message')?.touched && frForm.get('message')?.hasError('minlength')">{{'Min length equals' | translate}} <strong>10</strong></mat-error>
			<mat-error *ngIf="frForm.get('message')?.touched && frForm.get('message')?.hasError('required')">{{ 'This field is required' | translate }}</mat-error>
			<div class="d-flex justify-content-end mt-3">
				<dx-button
					id="add-ip"
					class="btn btn-default-small"
					[height]="35"
					[text]="loading ? ('Loading...' | translate) : ('Submit' | translate)"
					type="success"
					(onClick)="sendFeatureRequest()"
					[disabled]="!frForm.valid || loading">
				</dx-button>
			</div>
<!--			<div class="d-flex justify-content-end mt-2 mb-2">-->
<!--				<button class="btn btn-default-small btn-sm" [disabled]="!frForm.valid || loading" type="submit">{{loading ? ('Loading...' | translate) : ('Submit' | translate)}}</button>-->
<!--			</div>-->
			<small [innerHTML]="'FEATURE_REQUEST_TEXT' | translate"></small>
		</form>
	</ng-container>
</div>

<ng-template #successTemplate>
	<div class="thanks-block">{{'Thanks for sharing your idea! Our team will review it soon.' | translate}}</div>
</ng-template>
