import {Component, HostListener, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FeatureRequestService} from './feature-request.service';
import {MatDialogRef} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-feature-request',
  templateUrl: './feature-request.component.html',
  styleUrls: ['./feature-request.component.scss']
})
export class FeatureRequestComponent implements OnInit {

  frForm: FormGroup;
  
  loading: boolean = false;
  showSuccessMessage = false;
  constructor(private frService: FeatureRequestService,public dialogRef: MatDialogRef<FeatureRequestComponent>) {
    this.frForm = new FormGroup({
      message: new FormControl(null, [Validators.required, Validators.minLength(10)])
    })
  }

  ngOnInit(): void {
  }
  
  sendFeatureRequest() {
    this.loading = true;
    this.frService.sendFeatureRequest(this.frForm.value).pipe(finalize(() => {
      this.loading = false;
    })).subscribe(data => {
      if (data && data.success) {
        this.showSuccessMessage = true;
        this.frForm.reset();
        setTimeout(() => {
          this.dialogRef.close()
        }, 5000)
      }
    })
  }
}
