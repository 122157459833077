import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureRequestService {

  constructor(private http: HttpClient) { }
  
  sendFeatureRequest(postData: {message: string}): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>('monetizer_api/api/user/send_feature', postData)
  }
}
