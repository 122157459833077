<div class="d-flex flex-column flex-root h-100vh bgi-size-cover bg-img" style="background-image:
url('https://dataimpulse.com/wp-content/uploads/2023/10/top-bg-light.webp')">

	<!--begin::Authentication - Sign-in -->
	<div class="d-flex flex-column flex-lg-row flex-column-fluid">
		<div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2">
			<div class="d-flex flex-center flex-column flex-lg-row-fluid px-5 py-7">
				<div class="form-wrapper shadow-lg rounded w-lg-500px">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
		<div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1">
			<div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">

				<a href="https://dataimpulse.com/" class="mb-0 mb-auto">
					<picture>
						<source srcset="https://dataimpulse.com/wp-content/uploads/2023/10/dataimpulse-light.png"
						        media="(prefers-color-scheme: dark)">
						<img src="{{logoUrl}}" class="h-60px" alt="logo dark">
					</picture>
				</a>

				<ng-container *ngIf="regSource !== 'affiliate'; else affBlock">
					<h1 class="d-none d-lg-block fs-2qx fw-bolder text-center mb-7 font-color">
						{{ 'High-Quality Proxies' | translate }}
					</h1>
					<div class="d-none d-lg-block fs-base text-center text-wrap-balance font-color">
						{{ 'Easily integrate an unblockable IP pool with the best pricing in the market.' | translate }}
						{{ 'Pay only for the gigabytes you use and forget about monthly fees.' | translate }}
					</div>
					<div class="d-none d-lg-block fs-base text-center text-wrap-balance font-color mt-3">
						<ul class="feature-list">
							<li>{{ 'Pay-as-you-go' | translate }}</li>
							<li>{{ '24/7 Support' | translate }}</li>
							<li>{{ 'No Time Limits' | translate }}</li>
							<li>{{ 'Features Per Request' | translate }}</li>
							<li>{{ '7-day Refund Policy' | translate }}</li>
							<li>{{ 'Simply Integration' | translate }}</li>
							<li>{{ 'Country Targeting' | translate }}</li>
							<li>{{ 'State/City/Zip/ASN Targeting' | translate }}</li>
						</ul>
					</div>
				</ng-container>
				<ng-template #affBlock>
					<img src="./assets/images/aff_percentage.gif" alt="" class="img-responsive aff-img">
					<h1 class="d-none d-lg-block fs-2qx fw-bolder text-center mb-7 font-color" [innerHTML]="'AFFILIATE_SIGNUP_HEADER' | translate">
<!--						{{ 'Earn Commissions by Promoting High Quality Proxies' | translate }}-->
					</h1>
				</ng-template>

				<div class="d-flex align-items-center mt-3 mb-2">
					<!--          &lt;!&ndash; TrustBox widget - Micro Review Count &ndash;&gt;-->
					<!--          <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad"
								  data-businessunit-id="64ba846811c8af68008b2c7e" data-style-height="24px" data-style-width="100%" [attr.data-theme]="theme
								.value" data-min-review-count="50" data-style-alignment="center">-->
					<!--            <a href="https://www.trustpilot.com/review/dataimpulse.com" target="_blank" rel="noopener">Trustpilot</a>-->
					<!--          </div>-->
					<!--          &lt;!&ndash; End TrustBox widget &ndash;&gt;-->

					<!-- TrustBox widget - Micro Combo -->
					<div class="trust-block-desktop">
						<div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af"
						     data-businessunit-id="64ba846811c8af68008b2c7e" data-style-height="20px" data-style-width="430px" [attr.data-theme]="theme.value">
							<a href="https://www.trustpilot.com/review/dataimpulse.com" target="_blank" rel="noopener">Trustpilot</a>
						</div>
					</div>
					<!-- End TrustBox widget -->

					<!-- TrustBox widget - Micro TrustScore -->
					<div class="trust-block-mobile">
						<div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b637fa0340045cd0c936"
						     data-businessunit-id="64ba846811c8af68008b2c7e" data-style-height="20px" data-style-width="100%" [attr.data-theme]="theme.value">
							<a href="https://www.trustpilot.com/review/dataimpulse.com" target="_blank" rel="noopener">Trustpilot</a>
						</div>
					</div>
					<!-- End TrustBox widget -->
				</div>


        <div class="d-flex align-items-center mt-5 mb-auto">
          <div class="d-flex gap-16 cert-block">
            <div class="certificate"><img src="./assets/images/ISO-27001.webp"></div>
            <div class="certificate"><img src="./assets/images/newcomer-of-the-year-2024.webp"></div>
          </div>
        </div>
			</div>
		</div>
	</div>
</div>
